import DataCache from "./DataCache";
import permissions from "./permissions";
import tenantConfiguration from "./tenantConfiguration";

const localDataCache = {
  tenantConfiguration,
  permissions,

  async load() {
    Object.values(this).forEach((cache) => {
      if (cache instanceof DataCache) {
        cache.load();
      }
    });
  },
  remove() {
    Object.values(this).forEach((cache) => {
      if (cache instanceof DataCache) {
        cache.remove();
      }
    });
  },
};

export default localDataCache;

import type { PermissionType } from "@tamarack/sdk";
import { getApiPermissionsUrl } from "../../routes/api.permissions/utils";
import DataCache from "./DataCache";

const permissions = new DataCache<PermissionType[]>("permissions", async () => {
  const response = await fetch(getApiPermissionsUrl().toString());
  const data = await response.json();

  return data.permissions;
});

export default permissions;

export default class DataCache<T> {
  key: string;
  fetcher: () => Promise<T>;
  cached = false;

  constructor(key: string, fetcher: () => Promise<T>) {
    this.key = key;
    this.fetcher = fetcher;
    this.cached = !!window.localStorage.getItem(this.key);
  }

  async load() {
    const data = await this.fetcher();
    await this.set(data);
  }

  async get(): Promise<T | undefined> {
    /**
     * Load the data if not cached yet
     */
    if (!this.cached) {
      await this.load();
    }

    return this.getFromStorage();
  }

  getFromStorage() {
    /**
     * Return the cached data
     */
    try {
      const rawData = window.localStorage.getItem(this.key);

      if (!rawData) {
        return undefined;
      }

      const data = JSON.parse(rawData);
      return data as T;
    } catch (e) {
      return undefined;
    }
  }

  set(newValue: T) {
    window.localStorage.setItem(this.key, JSON.stringify(newValue));

    if (newValue !== undefined) {
      this.cached = true;
    }
  }

  remove() {
    window.localStorage.removeItem(this.key);
    this.cached = false;
  }
}

import { type TenantConfiguration } from "@tamarack/sdk";
import { getTenantConfigurationUrl } from "../../routes/api.tenant-configuration/utils";
import DataCache from "./DataCache";

const tenantConfiguration = new DataCache<TenantConfiguration>("tenantConfiguration", async () => {
  const response = await fetch(getTenantConfigurationUrl().toString());
  const data = await response.json();

  return data.tenantConfiguration;
});

export default tenantConfiguration;
